import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/all-images/buses-img/mini-bus-rental.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "20px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h2 className="section__title" style={{ fontSize: "2rem" }}>
                Premium Bus Rental Services in Dubai
              </h2>
              <p className="section__description">
                Discover luxury travel options with <Link
                      to="https://www.dubairentaltour.com/"
                      style={{ textDecoration: "none", color: "#888888" }}
                    >DubaiRentalTour</Link> Transport,
                your premier provider of van rental services in Dubai. Whether
                you're arranging transportation for a:
                <br />
                <i className="ri-star-s-fill" style={{ color: "#f9a826" }}>
                  {" "}
                  <span style={{ color: "#888888" }}>
                    <Link
                      to="https://www.dubairentaltour.com/buses/corporate-shuttle"
                      style={{ textDecoration: "none", color: "#888888" }}
                    >
                      Corporate Event
                    </Link>
                  </span>
                </i>
                <br />
                <i className="ri-star-s-fill" style={{ color: "#f9a826" }}>
                  {" "}
                  <span style={{ color: "#888888" }}>
                    <Link
                      to="https://www.dubairentaltour.com/buses/group-travel-bus"
                      style={{ textDecoration: "none", color: "#888888" }}
                    >
                      Group Adventure
                    </Link>
                  </span>
                </i>
                <br />
                <i className="ri-star-s-fill" style={{ color: "#f9a826" }}>
                  {" "}
                  <span style={{ color: "#888888" }}>
                    <Link
                      to="https://www.dubairentaltour.com/buses/city-sightseeing-tour-bus"
                      style={{ textDecoration: "none", color: "#888888" }}
                    >
                      Dubai City Tour
                    </Link>
                  </span>
                </i>
                <br />
                <i className="ri-star-s-fill" style={{ color: "#f9a826" }}>
                  {" "}
                  <span style={{ color: "#888888" }}>
                    {" "}
                    <Link
                      to="https://www.dubairentaltour.com/buses/airport-transfer"
                      style={{ textDecoration: "none", color: "#888888" }}
                    >
                      Hotel and Airport Transfers
                    </Link>
                  </span>
                </i>
                <br />
                Our fleet ensures comfort and style throughout your journey.
                <br />
                <h2
                  className="section__title"
                  style={{ fontSize: "1.5rem", marginTop: "10px" }}
                >
                  Ready to Embark on Your Next Adventure? Contact Us Today
                </h2>
                <button className="btn contact__btn mt-2">
                  <Link
                    to="https://wa.me/971582058098?text=Hello How Can I Help You?"
                    target="blank"
                  >
                    Contact Us
                  </Link>
                </button>
              </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img
                src={aboutImg}
                alt="Premium Bus Rental Services in Dubai"
                title="Premium Bus Rental Services in Dubai"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
