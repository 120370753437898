import React from "react";
import { Container, Row, Col } from "reactstrap";
import HeroSlider from "../components/UI/HeroSlider";
import AboutSection from "../components/UI/AboutSection";
import ServicesList from "../components/UI/ServicesList";
import busData from "../assets/data/busData";
import BusItem from "../components/UI/BusItem";
import Testimonial from "../components/UI/Testimonial";
import BlogList from "../components/UI/BlogList";
import { Helmet } from "react-helmet";
import CompanyContent from "../components/UI/CompanyContent";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Bus for Rent in Dubai | DubaiRentalTour - Luxury & Affordable Options</title>
        <meta
          name="description"
          content="Explore a wide range of buses for rent in Dubai with DubaiRentalTour. From luxury coaches to mini buses, find the perfect option for your corporate events, tours, or special occasions."
        />
        <meta
          name="keywords"
          content="Rent a Bus in Dubai, Dubai bus rental, bus hire Dubai, Dubai tour bus, affordable bus rental Dubai, reliable bus service Dubai, Dubai airport shuttle service, corporate bus rental Dubai, event bus rental Dubai"
        />
        <meta name="author" content="DubaiRentalTour" />
        <meta name="robots" content="index, follow" />
        <meta
          name="image"
          content="https://www.dubairentaltour.com/static/media/mini-bus-rental.be7d9c728e4fbbcad53a.png"
        />
        <meta name="image:alt" content="dubairentaltour" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <meta name="language" content="English" />
        <meta
          name="copyright"
          content="Copyright 2024, Developed by DubaiRentalTour. All rights reserved."
        />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <link rel="canonical" href="https://www.dubairentaltour.com" />
        <meta
          property="og:image"
          content="https://www.dubairentaltour.com/static/media/mini-bus-rental.be7d9c728e4fbbcad53a.png"
        />
        <meta property="og:image:alt" content="dubairentaltour" />
        <meta
          property="og:title"
          content="Bus for Rent in Dubai | DubaiRentalTour - Luxury & Affordable Options"
        />
        <meta
          property="og:description"
          content="Explore a wide range of buses for rent in Dubai with DubaiRentalTour. From luxury coaches to mini buses, find the perfect option for your corporate events, tours, or special occasions."
        />
      </Helmet>

      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section">
        <HeroSlider />
        {/* =========== about section ================ */}
        <AboutSection />
      </section>

      {/* =========== bus offer section ============= */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h2 className="section__title" style={{ fontSize: "3rem" }}>
                Our Fleet
              </h2>
            </Col>
            {busData.slice(0, 9).map((item) => (
              <BusItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>

      {/* ========== services section ============ */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title" style={{ fontSize: "3rem" }}>
                Popular Services
              </h2>
            </Col>
            <ServicesList />
          </Row>
        </Container>
      </section>

      {/* =========== testimonial section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-4 text-center">
              <h2 className="section__title" style={{ fontSize: "3rem" }}>
                Testimonials
              </h2>
            </Col>
            <Testimonial />
          </Row>
        </Container>
      </section>

      {/* =============== blog section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title" style={{ fontSize: "3rem" }}>
                Blogs
              </h2>
            </Col>
            <BlogList />
            <CompanyContent />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
