import React from 'react';
import whatsappLogo from '../../assets/all-images/whatsappLogo.png';

const WhatsAppIcon = () => {
    return (
        <div className="fixed-bottom right-100 p-3" style={{ zIndex: '6', right: 'initial' }}>
            <a href="https://wa.me/971582058098?text=Hello How Can I Help You?" target="_blank">
                <img src={whatsappLogo} width="60" alt="Whatsapp_Logo" title='Whatsapp_Logo' />
            </a>
        </div>
    );
};

export default WhatsAppIcon;
