import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import BusItem from "../components/UI/BusItem";
import busData from "../assets/data/busData";
import { Helmet } from "react-helmet";

const BusListing = () => {
  const [visibleCount, setVisibleCount] = useState(9);

  // Handler function to load more items
  const loadMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 9);
  };

  // Keywords for SEO
  const keywords = [
    "bus rental Dubai",
    "Dubai bus hire",
    "rent a bus in Dubai",
    "Dubai mini bus rental",
    "luxury bus rental Dubai",
    "school bus rental Dubai",
    "bus rental services in Dubai",
    "Dubai tour bus rental",
    "cheap bus rental Dubai",
    "corporate bus rental Dubai",
    "bus hire Dubai",
    "Dubai coach hire",
    "minibus rental Dubai",
    "party bus rental Dubai",
    "charter bus Dubai",
    "transportation services Dubai",
    "Dubai bus rental deals",
    "Dubai shuttle bus rental",
    "bus lease Dubai",
    "Dubai passenger van rental",
  ].join(", ");

  return (
    <>
      <Helmet>
        <title>Bus Rental Services in Dubai | DubaiRentalTour</title>
        <meta name="description" content="Explore a variety of buses available for rent in Dubai. Whether you need luxury buses, mini buses, or coach buses, find the ideal rental option for your travel plans." />
        <meta name="keywords" content={keywords} />
        <meta name="author" content="DubaiRentalTour" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta name="copyright" content="Copyright 2024, Developed by DubaiRentalTour. All rights reserved." />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta property="og:url" content="https://www.dubairentaltour.com/buses" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Bus Rental Services in Dubai | DubaiRentalTour" />
        <meta property="og:description" content="Explore a variety of buses available for rent in Dubai. Whether you need luxury buses, mini buses, or coach buses, find the ideal rental option for your travel plans." />
        <meta property="og:image" content="https://www.dubairentaltour.com/static/media/luxury-bus-rental.e40fc5c44e2bb583abbc.png" />
        <link rel="canonical" href="https://www.dubairentaltour.com/buses" />
      </Helmet>

      <CommonSection title="Bus Listing" />

      <section>
        <Container>
          <Row>
            {busData.slice(0, visibleCount).map((item) => (
              <BusItem item={item} key={item.id} />
            ))}
          </Row>
          {visibleCount < busData.length && (
            <Row>
              <Col className="text-center">
                <Button color="dark" onClick={loadMoreItems}>
                  Load More
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default BusListing;
