import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

// import ava01 from "../../assets/all-images/ava-1.jpg";
// import ava02 from "../../assets/all-images/ava-2.jpg";
// import ava03 from "../../assets/all-images/ava-3.jpg";
// import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Our recent trip with DubaiRentalTour was nothing short of fantastic. From the easy online booking to the friendly driver, every aspect was smooth and stress-free. Highly recommend!
        </p>

        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava01} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">John Doe</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Used DubaiRentalTour Bus Rental for a team outing, and it was a hit! The spacious and comfortable bus, along with the professional service, made our journey enjoyable. Will definitely choose them again!
        </p>

        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava02} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Jane Smith</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Our group was on a tight schedule, and DubaiRentalTour exceeded our expectations. The bus arrived on time, and the driver navigated through traffic expertly. Dependable service all around!
        </p>
        {/* 
        <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava03} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Mark Johnson</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          The bus provided was not only spacious but also impeccably clean. It made our trip comfortable, and the amenities exceeded our expectations. A great choice for group travel!
        </p>

        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava04} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Emily Davis</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Booking a van with DubaiRentalTour for our family trip was the best decision. The van was spacious and comfortable, making our journey around Dubai delightful. Highly recommended!
        </p>
        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava05} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Michael Brown</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Exceptional service from start to finish. DubaiRentalTour's bus rental made our corporate event logistics seamless. The staff was friendly and professional. Will definitely use their services again!
        </p>
        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava06} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Sarah Lee</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          Our school trip was a huge success thanks to DubaiRentalTour. The bus was punctual and the ride was smooth. The kids enjoyed the comfortable seating and we had a great time exploring Dubai.
        </p>
        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava07} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Tom Harris</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>

      <div className="testimonial py-4 px-3">
        <p className="section__description">
          DubaiRentalTour’s airport transfer service is top-notch. The driver was waiting for us at the airport, and the ride to our hotel was smooth and comfortable. Highly efficient and reliable service!
        </p>
        {/* <div className="mt-3 d-flex align-items-center gap-4">
          <img src={ava08} alt="" className="w-25 h-25 rounded-2" />

          <div>
            <h6 className="mb-0 mt-3">Linda White</h6>
            <p className="section__description">Customer</p>
          </div>
        </div> */}
      </div>
    </Slider>
  );
};

export default Testimonial;
