import React, { Fragment } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Routers from '../../routers/Routers';
import WhatsAppIcon from '../WhatsAppIcon/WhatsAppIcon';
import ContactIcon from '../WhatsAppIcon/ContactIcon';

const Layout = () => {
  return (
      <Fragment>
      <Header />
      <div>
        <Routers />
      </div>
      <Footer />
      <ContactIcon/>
      <WhatsAppIcon />
    </Fragment>
  );
};

export default Layout;
