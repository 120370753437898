const serviceData = [
  // {
  //   id: 1,
  //   title: "Luxury Van Rental Services in Dubai",
  //   icon: "ri-map-pin-2-line",
  //   desc: "Experience top-tier luxury van rental services in Dubai. Travel in style and comfort with our premium fleet.",
  //   keywords: ["Luxury van hire Dubai", "Van rental Dubai"],
  // },

  // {
  //   id: 2,
  //   title: "Exclusive Party Bus Rental in Dubai",
  //   icon: "ri-community-line",
  //   desc: "Turn your events into memorable experiences with our exclusive party bus rental service in Dubai. Perfect for any celebration.",
  //   keywords: ["Party bus rental Dubai", "Event transportation services Dubai"],
  // },

  // {
  //   id: 3,
  //   title: "Unlimited Miles Bus Rental in Dubai",
  //   icon: "ri-roadster-line",
  //   desc: "Travel without limits with our unlimited miles bus rental service in Dubai. Perfect for long-distance journeys.",
  //   keywords: ["Unlimited miles bus rental Dubai", "Bus rental UAE"],
  // },

  // {
  //   id: 4,
  //   title: "Fast & Easy Bus Booking in Dubai",
  //   icon: "ri-timer-flash-line",
  //   desc: "Enjoy a fast and easy bus booking process in Dubai. Get on the road quickly with our streamlined service.",
  //   keywords: ["Bus booking Dubai", "Quick bus rental Dubai"],
  // },

  // {
  //   id: 5,
  //   title: "Convenient Van Rental Pickup Locations",
  //   icon: "ri-map-pin-line",
  //   desc: "Choose from multiple convenient van rental pickup locations across Dubai. Start your journey with ease.",
  //   keywords: ["Van rental Dubai", "Convenient van pickup Dubai"],
  // },

  // {
  //   id: 6,
  //   title: "Reliable Airport Transfer Services in Dubai",
  //   icon: "ri-flight-takeoff-line",
  //   desc: "Ensure a smooth and reliable airport transfer with our top-notch services in Dubai. Travel worry-free to and from the airport.",
  //   keywords: ["Airport transfer Dubai", "Dubai airport shuttle service"],
  // },

  {
    id: 7,
    title: "Affordable Minibus Rental Dubai",
    icon: "ri-bus-2-line",
    desc: "Get affordable minibus rental services in Dubai for small groups and family trips. Enjoy budget-friendly travel.",
    keywords: ["Affordable minibus rental Dubai", "Minibus hire Dubai"],
  },

  {
    id: 8,
    title: "Corporate Event Transportation in Dubai",
    icon: "ri-building-2-line",
    desc: "Professional corporate event transportation solutions in Dubai. Make your corporate events a success with our services.",
    keywords: ["Corporate event transportation Dubai", "Corporate bus rental Dubai"],
  },

  {
    id: 9,
    title: "Dubai City Sightseeing Bus Rental",
    icon: "ri-building-line",
    desc: "Explore Dubai's attractions with our city sightseeing bus rental service. Comfortable and informative city tours await.",
    keywords: ["Dubai sightseeing bus", "City tour bus rental Dubai"],
  },

  {
    id: 10,
    title: "Private Bus Charter Services in Dubai",
    icon: "ri-group-line",
    desc: "Book private bus charter services in Dubai for exclusive travel experiences. Ideal for private groups and special occasions.",
    keywords: ["Private bus rental Dubai", "Bus charter services Dubai"],
  },

  {
    id: 11,
    title: "Group Transportation Services in Dubai",
    icon: "ri-team-line",
    desc: "Efficient and reliable group transportation services in Dubai. Perfect for large groups and special events.",
    keywords: ["Group transportation Dubai", "Group bus rental Dubai"],
  },

  {
    id: 12,
    title: "Dubai Excursion Bus Rental",
    icon: "ri-landscape-line",
    desc: "Plan your excursions with our specialized bus rental services in Dubai. Enjoy seamless travel for your outdoor adventures.",
    keywords: ["Dubai excursion bus rental", "Excursion bus hire Dubai"],
  },
];

export default serviceData;
