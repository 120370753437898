import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import blogData from "../assets/data/blogData.js";
import { Helmet } from "react-helmet";
import "../styles/blog-details.css";
import BookingForm from "../components/UI/BookingForm.jsx";
import busData from "../assets/data/busData.js";
import BusItem from "../components/UI/BusItem.jsx";

const BlogDetails = () => {
  const [visibleCount, setVisibleCount] = useState(9);
  const { slug } = useParams();
  const navigate = useNavigate();
  const blog = blogData.find(
    (blog) =>
      blog.title.toLowerCase().replace(/\s+/g, "-") === slug.toLowerCase()
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [blog]);

  useEffect(() => {
    if (!blog) {
      navigate("/");
    }
  }, [blog, navigate]);

  if (!blog) {
    return null;
  }

  const loadMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 9);
  };

  const formattedBlogTitle = encodeURIComponent(
    blog.title.toLowerCase().replace(/\s+/g, "-")
  );
  const keywords = blog.keywords.join(", ");

  return (
    <>
      <Helmet>
        <title>{`${blog.title} | DubaiRentalTour`}</title>
        <meta
          name="description"
          content={`Read ${blog.title} and get expert travel and bus rental insights from DubaiRentalTour. Discover tips, guides, and stories to enhance your journey in Dubai.`}
        />
        <meta name="keywords" content={keywords} />
        <meta name="image" content={blog.imgUrl} />
        <meta name="image:alt" content={formattedBlogTitle} />
        <meta name="language" content="English" />
        <meta name="author" content={blog.author} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <link
          rel="canonical"
          href={`https://www.dubairentaltour.com/blogs/${formattedBlogTitle}`}
        />
        <meta property="og:title" content={`${blog.title} | DubaiRentalTour`} />
        <meta
          property="og:description"
          content={`Explore ${blog.title} with DubaiRentalTour's travel and bus rental insights. Learn tips and stories for a great journey.`}
        />
        <meta
          property="og:url"
          content={`https://www.dubairentaltour.com/blogs/${formattedBlogTitle}`}
        />
        <meta property="og:image" content={blog.imgUrl} />
        <meta property="og:image:alt" content={formattedBlogTitle} />
        <meta property="og:type" content="article" />
      </Helmet>

      <section>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <h2 className="section__title mt-4">{blog.title}</h2>

              <div className="blog__publisher d-flex align-items-center gap-4 mb-4">
                <span className="blog__author">
                  <i className="ri-user-line"></i> {blog.author}
                </span>

                <span className="d-flex align-items-center gap-1 section__description">
                  <i className="ri-calendar-line"></i> {blog.date}
                </span>

                <span className="d-flex align-items-center gap-1 section__description">
                  <i className="ri-time-line"></i> {blog.time}
                </span>
              </div>

              {blog.description.map((section) => (
                <div key={section.section}>
                  <h3 className="mt-4">{section.title}</h3>
                  {section.content ? (
                    <p className="section__description">{section.content}</p>
                  ) : (
                    section.subsections.map((subsection) => (
                      <div key={subsection.subsection}>
                        <h4 className="mt-3">{subsection.title}</h4>
                        <p className="section__description">{subsection.content}</p>
                      </div>
                    ))
                  )}
                </div>
              ))}
            </Col>

            <Col lg={{ size: 6, order: 2 }} md="12" className="text-center">
              <img
                src={blog.imgUrl}
                alt={`${blog.title} image`}
                title={blog.title}
                className="img-fluid w-100"
                style={{ maxHeight: "400px", objectFit: "cover" }}
              />
<br />
<br />
<br />
              <BookingForm />
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              <Container>
                <Row>
                  {busData.slice(0, visibleCount).map((item) => (
                    <BusItem item={item} key={item.id} />
                  ))}
                </Row>
                {visibleCount < busData.length && (
                  <Row>
                    <Col className="text-center">
                      <Button color="dark" onClick={loadMoreItems}>
                        Load More
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  );
};

export default BlogDetails;
