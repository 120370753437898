import React from "react";
import CommonSection from "../components/UI/CommonSection";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import driveImg from "../assets/all-images/drive.jpg";
import "../styles/about.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Reliable Bus Rental Services in Dubai | DubaiRentalTour</title>
        <meta name="description" content="Discover reliable bus rental services in Dubai for your travel needs. Explore our premium bus charter options, tour buses, and luxury rentals for a safe and comfortable ride." />
        <meta name="keywords" content="bus rental Dubai, rent a bus in Dubai, Dubai bus hire, Dubai bus rental services, charter bus Dubai, luxury bus rental Dubai, tour bus rental Dubai, mini bus rental Dubai" />
        <meta name="author" content="DubaiRentalTour" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta name="copyright" content="Copyright 2024, Developed by Dubai Rental Tour. All rights reserved." />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="revisit-after" content="7 days" />
        <link rel="canonical" href="https://www.dubairentaltour.com/about" />
        <meta property="og:url" content="https://www.dubairentaltour.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Reliable Bus Rental Services in Dubai | DubaiRentalTour" />
        <meta property="og:description" content="Discover reliable bus rental services in Dubai for your travel needs. Explore our premium bus charter options, tour buses, and luxury rentals for a safe and comfortable ride." />
        <meta property="og:image" content="https://www.dubairentaltour.com/static/media/mini-bus-rental.be7d9c728e4fbbcad53a.png" />
      </Helmet>

      {/* Page content */}
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="Safe and Reliable Bus Rental Services in Dubai" title="Safe and Reliable Bus Rental Services in Dubai" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Providing Safe Ride Solutions
                </h2>

                <p className="section__description">
                  At DubaiRentalTour, our reputation among transportation companies in Dubai is unparalleled. Tourists and residents alike trust us for our premium bus charter services. Whether you're looking for well-equipped tour buses, luxurious options for a comfortable and stylish journey, or versatile vans and reliable cars for rent, we have you covered.
                </p>
                <p className="section__description">
                  Our team is dedicated to ensuring that every ride is safe, comfortable, and tailored to meet your specific needs. We pride ourselves on offering top-notch customer service and the best transportation solutions in Dubai.
                </p>
                <div className="d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i className="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+971 58 205 8098</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
