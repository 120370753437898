import React from "react";
import "../../styles/booking-form.css";
import { Button, Form, FormGroup, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const BookingForm = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      const response = await fetch("https://formspree.io/f/xnqeevwe", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        // Clear form fields after successful submission if needed
        event.target.reset();
      } else {
        alert("Failed to submit form. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <Container>
      <h2>Book Your Premium Bus Today</h2>
      <p>
        Experience Dubai like never before with <Link to="https://www.dubairentaltour.com" style={{ textDecoration: "none" }}>Dubai Rental Tour’s</Link> premium bus
        rental service. Contact us now to book your luxury bus and embark on a
        memorable journey through one of the world’s most magnificent cities.
      </p>
      <h5 className="mb-4 fw-bold">Booking Information</h5>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="text"
                name="first_name"
                placeholder="Full Name"
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="tel"
                name="phone_number"
                placeholder="Phone Number"
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="text"
                name="from_address"
                placeholder="From Address"
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="text"
                name="to_address"
                placeholder="To Address"
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="date"
                name="journey_date"
                placeholder="Journey Date"
                className="form-control"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <input
                type="time"
                name="journey_time"
                placeholder="Journey Time"
                className="form-control"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup className="booking__form mb-4">
              <textarea
                rows={3}
                name="message"
                placeholder="What are you Looking for?"
                className="form-control"
              ></textarea>
            </FormGroup>
          </Col>
        </Row>
        <Button type="submit" style={{width:"200px", color:"white", background:"black"} }>Submit</Button>
      </Form>
    </Container>
  );
};

export default BookingForm;
