import React, { useEffect, useState } from "react";
import busData from "../assets/data/busData";
import { Container, Row, Col, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import BookingForm from "../components/UI/BookingForm";
import { Helmet } from "react-helmet";

import "../styles/blog-details.css"; // Assuming same CSS file for simplicity
import FAQ from "../components/UI/FAQ";
import BusItem from "../components/UI/BusItem";

const BusDetails = () => {
  const [visibleCount, setVisibleCount] = useState(9);
  const { slug } = useParams();
  const navigate = useNavigate();
  const singleBusItem = busData.find(
    (item) =>
      item.busName.toLowerCase().replace(/\s+/g, "-") === slug.toLowerCase()
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleBusItem]);

  useEffect(() => {
    if (!singleBusItem) {
      navigate("/");
    }
  }, [singleBusItem, navigate]);

  if (!singleBusItem) {
    return null;
  }

  const keywords = singleBusItem.keywords.join(", ");
  const formattedBusName = encodeURIComponent(
    singleBusItem.busName.toLowerCase().replace(/\s+/g, "-")
  );

  // Handler function to load more items
  const loadMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 9);
  };

  return (
    <>
      <Helmet>
        <title>{`${singleBusItem.busName} for Rent in Dubai | DubaiRentalTour`}</title>
        <meta name="keywords" content={keywords} />
        <meta
          name="description"
          content={`Explore details of ${singleBusItem.busName} for rent in Dubai: 
              - Rental price ${singleBusItem.price};
              - Monthly Rental price ${singleBusItem.mprice};
              - Capacity ${singleBusItem.capacity};
              Book your bus rental today for a comfortable journey.`}
        />
        <meta name="author" content="DubaiRentalTour" />
        <meta name="image" content={singleBusItem.imgUrl} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <link
          rel="canonical"
          href={`https://www.dubairentaltour.com/buses/${formattedBusName}`}
        />
        <meta
          property="og:title"
          content={`${singleBusItem.busName} for Rent in Dubai | DubaiRentalTour`}
        />
        <meta
          property="og:description"
          content={`Explore details of ${singleBusItem.busName} for rent in Dubai: 
               Rental price ${singleBusItem.price};
               Monthly Rental price ${singleBusItem.mprice};
               Capacity ${singleBusItem.capacity};
              Book your bus rental today for a comfortable journey.`}
        />
        <meta property="og:image" content={singleBusItem.imgUrl} />
        <meta property="og:image:alt" content={formattedBusName} />
        <meta
          property="og:url"
          content={`https://www.dubairentaltour.com/buses/${formattedBusName}`}
        />
        <meta property="og:type" content="article" />
      </Helmet>

      <section>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <h2>{singleBusItem.title1}</h2>
              <p>{singleBusItem.description1}</p>
            </Col>
            <Col lg="6" md="6">
              <div className="blog__details d-flex justify-content-center">
                <img
                  src={singleBusItem.imgUrl}
                  alt="Bus_Img"
                  title="Bus_Img"
                  className="img-fluid w-100"
                  style={{
                    maxHeight: "300px",
                    maxWidth: "400px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="blog__publisher d-flex align-items-center justify-content-center gap-4 mb-4">
                {/* Additional bus details here */}
              </div>
            </Col>
            
            <Col lg="12" md="12">
              <h2>{singleBusItem.title2}</h2>
              <ul>
                {singleBusItem.list2.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <br />
                <BookingForm />
              <br />
              <Container>
                <h1 className="text-center">Our Fleet</h1>
                <Row>
                  {busData.slice(0, visibleCount).map((item) => (
                    <BusItem item={item} key={item.id} />
                  ))}
                </Row>
                {visibleCount < busData.length && (
                  <Row>
                    <Col className="text-center">
                      <Button color="dark" onClick={loadMoreItems}>
                        Load More
                      </Button>
                    </Col>
                  </Row>
                )}
              </Container>
              <h2>{singleBusItem.title3}</h2>
              <br />
              {singleBusItem.sub1.map((subItem, index) => (
                <div key={index}>
                  <h3>{subItem.title1}</h3>
                  <p>{subItem.content1}</p>
                </div>
              ))}
              <br />
              <h2>{singleBusItem.title4}</h2>
              <br />
              {singleBusItem.sub2.map((subItem, index) => (
                <div key={index}>
                  <h3>{subItem.title2}</h3>
                  <p>{subItem.content2}</p>
                </div>
              ))}
              <br />
              <h2>{singleBusItem.title5}</h2>
              <br />
              {singleBusItem.sub3.map((subItem, index) => (
                <div key={index}>
                  <h3>{subItem.title3}</h3>
                  <p>{subItem.content3}</p>
                </div>
              ))}
              <h2>{singleBusItem.title6}</h2>
              <p>{singleBusItem.description6}</p>
              <div>
                <br />
              </div>
              <br />
              <br />
              <FAQ data={singleBusItem.Data} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BusDetails;
