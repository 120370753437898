import React from "react";
import { Container, Row } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import BlogList from "../components/UI/BlogList";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>Dubai Bus Rental Tips & Insights | DubaiRentalTour Blog</title>
        <meta name="description" content="Discover expert tips and insights on bus rentals in Dubai. From travel advice and destination guides to bus rental tips and customer stories, our blog is your ultimate resource." />
        <meta name="keywords" content="Dubai bus rental tips, bus hire advice, Dubai travel guides, bus rental blog, Dubai transportation tips" />
        <meta name="author" content="DubaiRentalTour" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta name="revisit-after" content="7 days" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <link rel="canonical" href="https://www.dubairentaltour.com/blogs" />
        <meta property="og:url" content="https://www.dubairentaltour.com/blogs" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Dubai Bus Rental Tips & Insights | DubaiRentalTour Blog" />
        <meta property="og:description" content="Discover expert tips and insights on bus rentals in Dubai. From travel advice and destination guides to bus rental tips and customer stories, our blog is your ultimate resource." />
        <meta property="og:image" content="https://www.dubairentaltour.com/static/media/blog-6.cf450772b26ac1e0b243.jpg" />
      </Helmet>

      <CommonSection title="Blogs" />
      <section>
        <Container>
          <Row>
            <BlogList />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Blog;
