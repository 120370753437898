import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import BusListing from "../pages/BusListing";
import BusDetails from "../pages/BusDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import { Helmet } from "react-helmet";

const Routers = () => {
  return (
    <>
    <Helmet>
    <link rel="canonical" href="https://www.dubairentaltour.com" />
    </Helmet>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/buses" element={<BusListing />} />
      <Route path="/buses/:slug" element={<BusDetails />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    </>
  );
};

export default Routers;
