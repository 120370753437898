import React from "react";
import Slider from "react-slick";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import "../../styles/hero-slider.css";

const HeroSlider = () => {
  const settings = {
    fade: true,
    speed: 5000,
    autoplaySpeed: 5000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings} className="hero__slider">
      <div className="slider__item slider__item-01 mt0">
        <Container>
          <div className="slider__content ">
            <h4 className="text-light mb-3">Affordable Bus Rental from AED 400/Day</h4>
            <h2 className="text-light mb-4">Book Today and Save 10%</h2>

            <button className="btn reserve__btn mt-4">
              <Link to="/buses" target="_blank">
              Book Your Trip Now
              </Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-02 mt0">
        <Container>
          <div className="slider__content ">
            <h4 className="text-light mb-3">Best Value Bus Rentals in Dubai</h4>
            <h2 className="text-light mb-4">Reserve Today and Enjoy 10% Discount</h2>

            <button className="btn reserve__btn mt-4">
              <Link to="/buses">Book Your Trip Now</Link>
            </button>
          </div>
        </Container>
      </div>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          <div className="slider__content ">
            <h4 className="text-light mb-3">Top-Rated Travel Experience</h4>
            <h2 className="text-light mb-4">Reserve Now for a 10% Discount</h2>

            <button className="btn reserve__btn mt-4">
              <Link to="/buses">Book Your Trip Now</Link>
            </button>
          </div>
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;
