import React, { useState } from "react";
import { Form, FormGroup, Input, Button } from "reactstrap";
import { RiMailSendLine } from "react-icons/ri";
import '../../styles/contacticon.css'

const ContactForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    message: "",
  });

  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Formspree endpoint
    const formEndpoint = "https://formspree.io/f/xnqeevwe";
    try {
      const response = await fetch(formEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Message sent successfully!");
        setFormData({
          name: "",
          email:"",
          number: "",
          message: "",
        });
      } else {
        alert("Failed to send message. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="fixed-bottom right-100 p-3" style={{ zIndex: '6', left: 'initial' }}>
      <div className="contact-form">
        <div className="contact-icon" onClick={toggleForm}>
          <RiMailSendLine size={70} style={{ color: "red" }} />
        </div>
        {/* <div className={`message ${isOpen ? 'hide' : 'show'}`}>Send an inquiry</div> */}
        <div className={`form-container ${isOpen ? 'expand' : 'collapse'}`}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="number"
                name="number"
                placeholder="Your Number"
                value={formData.number}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <Button type="submit">Send Message</Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
