import React, { useEffect } from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "../../styles/footer.css";

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  {
    path: "#",
    display: "Privacy Policy",
  },

  {
    path: "/buses",
    display: "Bus Listing",
  },
  {
    path: "/blogs",
    display: "Blog",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top when a new route is navigated to
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h2>
                <div className=" d-flex align-items-center gap-2">
                  <i class="ri-bus-line"></i>
                  <span>
                    Top #1 Bus Rental  <br /> Company in Dubai
                  </span>
                </div>
              </h2>
            </div>
            <p className="footer__logo-content">
              We provide luxury bus rental and transportation services across UAE for Dubai City Tours, Airport Transfers, Hotel Transfers, Group Travel, Sightseeing tours, Hop-on Hop-off tours, Staff transportation and more!
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info"> Hor Al Anz, Deira, Dubai.</p>
              <p className="office__info">Phone: +971 58 205 8098</p>

              <p className="office__info">Email: info@dubairentaltour.com</p>

              <p className="office__info">Office Time: 8am - 10pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Developed by
                DubaiRentalTour. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
