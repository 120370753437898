import React, { useState } from "react";
import { Col, Button } from "reactstrap"; // Assuming you are using Bootstrap for styling
import "../../styles/blog-item.css";
import { Link } from "react-router-dom";
import blogData from "../../assets/data/blogData"; // Import your JSON data

const BlogList = () => {
  const [visibleCount, setVisibleCount] = useState(6); // State to manage number of visible items

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 6); // Increase visible count by 6
  };

  return (
    <>
      {blogData.slice(0, visibleCount).map((item) => (
        <BlogItem item={item} key={item.id} />
      ))}

      {blogData.length > visibleCount && ( // Render "Load More" button if there are more items
        <div className="text-center mt-4">
          <Button color="dark" onClick={loadMore}>
            Load More
          </Button>
        </div>
      )}
    </>
  );
};

const BlogItem = ({ item }) => {
  const { imgUrl, title, author, date, time, description } = item;
  const formattedTitle = encodeURIComponent(
    title.toLowerCase().replace(/\s+/g, "-")
  );

  // Assuming description is an array, and you want to display only the first item
  const firstSection = description[0]; // Get the first section from the description array

  return (
    <Col lg={4} md={6} sm={6} className="mb-5">
      <div className="blog__item">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgUrl}
            alt="Blog_Img"
            title="Blog_Img"
            height="180"
            width="300"
          />
        </div>

        <div className="blog__info p-3" style={{ textAlign: "center" }}>
          <Link to={`/blogs/${formattedTitle}`} className="blog__title">
            {firstSection.title.length > 100
              ? `${firstSection.title.substr(0, 100)}...`
              : firstSection.title}
          </Link>

          {/* Displaying only the content of the first section */}
          <div>
            {firstSection.content.length > 100
              ? `${firstSection.content.substr(0, 100)}...`
              : firstSection.content}
          </div>

          <Link to={`/blogs/${formattedTitle}`} className="read__more">
            Read More
          </Link>

          <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
            <span className="blog__author">
              <i className="ri-user-line"></i> {author}
            </span>

            <div className=" d-flex align-items-center gap-3">
              <span className=" d-flex align-items-center gap-1 section__description">
                <i className="ri-calendar-line"></i> {date}
              </span>

              <span className=" d-flex align-items-center gap-1 section__description">
                <i className="ri-time-line"></i> {time}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BlogList;
