import React from "react";
import Faq from "react-faq-component";

// const data = {
//     title: "FAQ (Bus for Rent in Dubai)",
//     rows: [
//         {
//             title: "What types of buses are available for rent?",
//             content: `At DubaiRentalTour, we offer a wide variety of buses including charter buses, Mercedes Sprinter vans, 
//                       luxury buses, minibuses, luxury coaches, and coasters. We can accommodate all your transportation needs.`,
//         },
//         {
//             title: "How much does it cost to rent a bus?",
//             content: `Our rental prices start from AED 400. The cost depends on the type of bus and the rental duration.`,
//         },
//         {
//             title: "What rental terms do you offer?",
//             content: `We offer flexible rental terms including daily, weekly, and monthly contracts to suit your needs.`,
//         },
//         {
//             title: "Can I rent a bus with a driver?",
//             content: `Yes, all our bus rentals come with a professional driver. The driver's fee is included in the rental price.`,
//         },
//         {
//             title: "Are there any mileage restrictions?",
//             content: `No, there are no mileage restrictions on our bus rentals.`,
//         },
//         {
//             title: "What documents are required to rent a bus?",
//             content: `There are no specific requirements for renting a bus. Since the bus comes with a driver, 
//                       the driver's credentials and responsibilities are handled by us.`,
//         },
//         {
//             title: "Do you offer discounts for advance bookings?",
//             content: `Yes, we offer discounts for advance bookings. Booking in advance also ensures better availability of buses.`,
//         },
//         {
//             title: "Are there any additional fees?",
//             content: `No, there are no additional fees. The price we quote is the price you pay.`,
//         },
//         {
//             title: "Can I rent a bus for a corporate event or a wedding?",
//             content: `Absolutely! We cater to a wide range of events including corporate events, weddings, and other special occasions.`,
//         },
//         {
//             title: "What safety measures are in place for your buses?",
//             content: `Our buses are regularly maintained and inspected to ensure safety and reliability. 
//                       All our drivers are experienced and trained to provide the best service.`,
//         },
//     ],
// };

const styles = {
    titleTextColor: "black",
    rowTitleColor: "#f9a826",
};

const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};


function FAQ({ data }) {
    return (
        <div>
            <Faq data={data} styles={styles} config={config} />
        </div>
    );
}

export default FAQ;