import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/bus-item.css";

const BusItem = (props) => {
  const { imgUrl, busName, price, mprice, capacity } = props.item;
  const formattedBusName = encodeURIComponent(busName.toLowerCase().replace(/\s+/g, '-'));

  return (
    <Col xs={12} md={6} lg={4} className="mb-5">
      <div className="bus__item">
      <div className="bus__img">
      <Link to={`https://www.dubairentaltour.com/buses/${formattedBusName}`}>
  <img
    src={imgUrl}
    alt="Bus_Item"
    title="Bus_Item"
    className="bus__aimg"
    style={{ maxWidth: '80%', height: '160px', display: 'block', margin: '0 auto' }}
  />
  </Link>
</div>

        <div className="bus__item-content mt-4">
          <h4 className="section__title text-center"><Link to={`https://www.dubairentaltour.com/buses/${formattedBusName}`} style={{textDecoration:"none"}}>{busName}</Link></h4>
          <h6 className="rent__price text-center mt-">
            AED {price}<span>/ Day</span>
          </h6>
          <h6 className="rent__price text-center mt-">
            AED {mprice}<span>/ Monthly</span>
          </h6>
          <h6 className="rent__price text-center mt-">
            Capacity - {capacity}<span> Pax</span>
          </h6>

          <div className="bus__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
            {/* <span className=" d-flex align-items-center gap-1">
              <i class="ri-bus-line"></i> {model}
            </span> */}
            {/* <span className=" d-flex align-items-center gap-1">
              <i class="ri-team-fill"></i> {capacity}
            </span> */}
            {/* <span className=" d-flex align-items-center gap-1">
              <i class="ri-timer-flash-line"></i> {speed}
            </span> */}
          </div>

          
            <Link to="https://wa.me/971582058098?text=Hello How Can I Help You" target="blank"><button className=" w-50 bus__item-btn bus__btn-rent" style={{color:"white"}}>Rent</button></Link>
          

          
            <Link to={`/buses/${formattedBusName}`}><button className=" w-50 bus__item-btn bus__btn-details">Details</button></Link>
          
        </div>
      </div>
    </Col>
  );
};

export default BusItem;
