import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import { Helmet } from "react-helmet";

const socialLinks = [
  {
    url: "https://www.facebook.com/dubairentaltour",
    icon: "ri-facebook-line",
  },
  {
    url: "https://www.instagram.com/dubairentaltour",
    icon: "ri-instagram-line",
    color: "red"
  },
  {
    url: "https://www.linkedin.com/company/dubairentaltour",
    icon: "ri-linkedin-line",
  },
  {
    url: "https://twitter.com/dubairentaltour",
    icon: "ri-twitter-line",
  },
];

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | DubaiRentalTour</title>
        <meta name="description" content="Contact us to get in touch with our team. Fill out the form or reach out via phone or email. We're here to assist you with any inquiries." />
        <meta name="keywords" content="contact, get in touch, phone, email, address, social media" />
        <meta name="author" content="dubairentaltour" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="geo.region" content="AE" />
        <meta name="geo.placename" content="Dubai" />
        <meta name="revisit-after" content="7 days" />
        <meta name="language" content="English" />
        <meta name="distribution" content="global" />
        <meta name="copyright" content="Copyright 2024, Developed by DubaiRentalTour. All rights reserved." />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <meta property="og:url" content="https://www.dubairentaltour.com/contact" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://www.dubairentaltour.com/contact" />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <CommonSection title="Contact" />
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Get In Touch</h6>
              <Form
                action="https://formspree.io/f/xnqeevwe"
                method="POST"
              >
                <FormGroup className="contact__form">
                  <Input placeholder="Your Name" type="text" name="name" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Email" type="email" name="_replyto" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Number" type="tel" name="_num" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                    name="message"
                  ></textarea>
                </FormGroup>
                <button
                  className="contact__btn"
                  style={{ width: "200px", borderRadius: "5px" }}
                  type="submit"
                >
                  Send Message
                </button>
              </Form>
            </Col>
            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                  Hor Al Anz, Deira, Dubai.
                </p>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+971 58 205 8098</p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">info@dubairentaltour.com</p>
                </div>
                <h6 className="fw-bold mt-4">Follow Us</h6>
                <div className="d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <a
                      href={item.url}
                      key={index}
                      className="social__link-icon"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={item.icon}></i>
                    </a>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
